import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Bio from "../components/bio"

const IndexPage = props => {
  const siteTitle = "Stefi Rosca"
  const { location, data } = props
  const { allMdx, tagsGroup } = data
  const { edges } = allMdx
  const tags = tagsGroup.group

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Home "
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        slug=""
        description="A page to highlight my work, projects and learnings."
      />
      <Bio />
      <Link to="/blog/">
        <Button background="#352d39" marginBottom="1.5rem">
          Blog
        </Button>
      </Link>
      <div className="aboutMe-container">
        <h1>
          Hey people{" "}
          <span role="img" aria-label="wave emoji">
            👋
          </span>
        </h1>
        <p>
          My name is Stefi. I am a specialty coffee lover, skier, traveler,
          wanna be triathlete investor in training and someone who might spend
          too much time watching YouTube videos.{" "}
        </p>

        <p>I come from Romania, Brasov, and live in Spain, Barcelona.</p>

        <p>
          In my spare time, I enjoy watching educational and entertainment
          content on YouTube, reading, spending time with friends, and
          independent and adventurous travel!
        </p>

        <p>
          I plan to share some random thoughts, book reviews, productivity tips,
          frontend-focused tech articles, latte art adventures, and
          triathlon-related experiences. As I delve into new topics, expect
          changes along the way.
        </p>
      </div>
      <div className="aboutMe-container">
        <h1>Latest articles</h1>
        {edges.slice(0, 3).map((article, i) => {
          const { node } = article
          const { excerpt, fields, frontmatter, timeToRead } = node
          const { slug } = fields
          const { title, date, tags } = frontmatter

          return (
            <div key={i}>
              <Link style={{ boxShadow: `none` }} to={`/blog${slug}`}>
                <h2 className="talks-title">{title}</h2>
              </Link>

              <p className="searchPosts-postInfo">
                {`${date} • ${timeToRead} min read`}{" "}
                {tags && (
                  <Link
                    style={{ boxShadow: `none` }}
                    to={`/blog/tags/${tags[0].toLowerCase()}`}
                    className="topicsLink"
                  >
                    <span>{`• #${tags[0]} `}</span>
                  </Link>
                )}
              </p>
              <p>
                {excerpt}{" "}
                <Link style={{ boxShadow: `none` }} to={`/blog${slug}`}>
                  <span className="readMoreLink">read more</span>
                </Link>
              </p>
            </div>
          )
        })}
      </div>
      <div className="aboutMe-container">
        <h1 className="topicsTitle">Topics</h1>
        <p className="topicsList">
          {tags.map((tag, index) => {
            return (
              <>
                <Link
                  style={{ boxShadow: `none` }}
                  className="topics"
                  to={`/blog/tags/${tag.fieldValue.toLowerCase()}`}
                >
                  <span>{`#${tag.fieldValue} `}</span>
                </Link>
              </>
            )
          })}
        </p>
      </div>

      <Link to="/blog/">
        <Button background="#352d39" marginTop="35px">
          Go to Blog
        </Button>
      </Link>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
      edges {
        node {
          timeToRead
          excerpt(pruneLength: 200)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }

    tagsGroup: allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`
